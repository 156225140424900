import Block from '../block'

export default class BlockForm extends Block {
	static title = 'block-form'

	constructor($el) {
		super($el)

		this.form = this.$el.find('form')
		this.formMessage = this.$el.findElement('message')
		this.formMessageButton = this.$el.findElement('message-close')
		this.loader = this.$el.findElement('loader')

		if (this.$el.hasModifier('lightbox')) {
			// for lightbox open
			this.toggleButton = this.$el.findElement('toggle')
			// for lightbox close
			this.closeButton = this.$el.findElement('close')
			this.cancelButton = this.$el.findElement('cancel')
			this.lightboxOverlay = this.$el.findElement('fields-overlay')

			this.toggleButton.on('click', () => {
				// use modifier because of precedence
				this.$el.addModifier('lightbox-active')
				// prevent site from scrolling while the lightbox is open
				$('body').css('overflow', 'hidden')
			})

			this.closeButton
				.add(this.cancelButton)
				.add(this.lightboxOverlay)
				.on('click', () => {
					this.$el.removeModifier('lightbox-active')
					// revert body overflow so scrolling is possible again
					$('body').css('overflow', 'auto')
				})
		}

		this.formMessageButton.on('click', () => {
			this.formMessage.addClass('hidden')
		})

		this.form.on('submit', (e) => {
			this.setLoading(true)
			e.preventDefault()

			const data = new FormData(this.form.get(0))
			data.append('ajax', true)

			$.ajax({
				method: 'POST',
				processData: false,
				contentType: false,
				cache: false,
				url: window.location.href,
				data: data,
			})
				.done((json) => {
					if (!json.error && json.success) {
						// hide lightbox after send
						if (this.$el.hasModifier('lightbox')) {
							this.$el.removeModifier('lightbox-active')
							// revert body overflow so scrolling is possible again
							$('body').css('overflow', 'auto')
						}

						this.form.trigger('reset')
						this.formMessage.removeClass('hidden')

						$(document).trigger('message:success', json.message)
					} else {
						$(document).trigger('message:error', json.message)
					}
				})
				.fail((json) => {
					$(document).trigger('message:error', json.message)
				})
				.always(() => this.setLoading(false))
		})
	}

	setLoading(bool) {
		if (bool) {
			this.form.find('button[type="submit"]').prop('disabled', true)
			this.loader.removeClass('hidden')
		} else {
			this.form.find('button[type="submit"]').prop('disabled', false)
			this.loader.addClass('hidden')
		}
	}
}
